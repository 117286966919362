@import "../../../styles/colors";

.drawer .drawer-menu .drawer-menu-item {
  height: 50px;
  line-height: 50px;
  padding-left: 16px;

  .link {
    color: $drawer-inactive-item-fg-color;
    font-family: 'Roboto', sans-serif;
    display: block;
    font-size: 14px;
    letter-spacing: .7px;
  }

  &:hover, &.active {
    border-radius: 60px 0 0 60px;

    .link {
    //  font-weight: bold;
    //  font-size: 16px; /* Increase the font size on hover */
    }
  }

  &:hover {
    font-weight: normal;
    background: $primary-color-dim;
  }

  &.active {
    .link {
      color: $drawer-active-item-fg-color;
    }

    background: $drawer-active-item-bg-color;

    &::before, &::after {
      content: "";
      position: absolute;
      z-index: 1;
      right: 0;
      height: 80px;
      width: 40px;
    }

    &::before {
      top: -80px;
      border-bottom-right-radius: 80px;
      box-shadow: 0 40px 0 0 $drawer-active-item-bg-color;
    }

    &::after {
      top: 50px;
      border-top-right-radius: 80px;
      box-shadow: 0 -40px 0 0 $drawer-active-item-bg-color;
    }
  }
}