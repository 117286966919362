.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; /* Adjust the margin as needed */
}

.pagination-button {
    padding: 10px 20px;
    background: linear-gradient(to bottom, #1C3FAA, #12529B);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    transition: background 0.3s ease, transform 0.2s ease;
}
.pagination-button.disabled {
    background: #dcdcdc; /* Set a specific background color for disabled state */
    color: #555; /* Set a different text color for disabled state */
    cursor: not-allowed;
}

.pagination-button:hover:not(.disabled) {
    background: linear-gradient(to bottom, #12529B, #1C3FAA);
    transform: scale(1.05); /* Add a slight scale effect on hover */
}

.current-page {
    font-size: 16px;
    margin: 0 10px;
    font-weight: bold;
}


.custom-notification-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: red;
    color: white;
    text-align: center;
    font-weight: bold;
    z-index: 1000;
    cursor: pointer; /* Add this to make it clickable */
  }

.add-funds-button:hover {
    background-color: #0d427e;
}



th {
    background-color: #eceff5;
    color: white;
    transition: none; /* Disable hover effect */
}

.add-funds-column {
    background: transparent; /* Remove the white background */
  }

  tr:hover {
    background-color: #e2f7c1;

}


.custom-search-input {
    /* Background color */
    background-color: #ebebf2;
  

    height: 50px;
    width: 40%;
    border-width: 2px;
    border-color: rgb(201, 201, 254);
  
    /* Placeholder text styles */
    ::placeholder {
      color: #888;
      opacity: 0.7;
    }
  
    /* Remove outline on focus */
    :focus {
      outline: none;
      border-color: #1c3faa; /* Change border color on focus */
      box-shadow: 0 0 5px rgba(28, 63, 170, 0.5); /* Add a subtle box shadow on focus */
    }
  }
  