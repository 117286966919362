
.profile-fragment{
  
  display: flex;
  flex-direction: column;
  text-align: center;
  
  .active{
    display: grid !important;
  }
  
  .borders{
    border-bottom : 3px solid rgb(55,58,163);
     
  }  

  .profile-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
  }

  .profile-heading{
    font-size: 32px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .profile-email{
    font-size: 24px;
    margin-bottom: 20px;
  }

  .nav{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 0px auto 40px;


    li{
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }

  }

  #City-div{
    display: none;
  }

  #Wallet{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    display: none;

    p{
      letter-spacing: 0.8px;
      font-size: 20px;
    }

    .box{
      margin-top: 20px;display: flex;
      gap: 20px;


      input{
        padding: 5px 10px;
        border: 2px solid rgb(55,58,163);
        border-radius: 16px;
        text-align: center;
        outline: none;
      }

      button{
        background-color: rgb(55,58,163);
        padding: 0px 14px;
        border-radius: 20px;
        color: #fff;
        font-weight: bold;
        transition: 0.5s;

        &:hover{
          background-color: #fff;
          color: rgb(55,58,163);
          border: 2px solid rgb(55,58,163);
        }
      }
    }
  }

  #CNIC{
    margin-top: 50px;
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;
    margin: auto;
    display: none;
  }
  
  #Numberplate{
    width: 60%;
    margin: auto;
    display: none;
  }




}

#category-card{
  display: grid;
  width: 40%;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  display: none;

  .cat-img{
    transform: scale(0.8);
    overflow: hidden;
  }

  .cate-info{
    align-self: center;
    text-align: center;
    

    .category-name{
      font-weight: bold;
    }

    span{
      font-weight: bold;
    }
  }
  .active{
    display: grid !important;
  }
  
  
}

