@import "../../../styles/colors";

.drawer .drawer-menu {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}