@import "../../../styles/colors";


.main-activity {
  background-color: $primary-color;

  .app-bar-bottom-divider {
    height: 1px;
    background: $appbar-bottom-divider-bg-color;
  }

  .content-container {
    &::-webkit-scrollbar {
      display: block;
      background-color: $secondary-color;
      width: 0.5rem;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color:  $primary-color;
      border-radius: 10px;
    }
  }
}
