.starline-game-chart-94659019-e255-491d-8940-94a7e8bdc8fd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  table {
    //border: 1px solid black;

    .chart-row {
      display: flex;

      .chart-col {
        border: 2px solid black;
        background: white;
        padding: 8px;
        margin: 0.5px;
      }
    }

    .head-row {

      .chart-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .head-col {
        flex-direction: column;
        flex: 1;
        //width: 100px;
      }

      .date-col {
        flex: 1;
        //width: 200px;
      }

    }

    .body-row {
      .chart-col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .date-col {
        flex: 1;
      }

      .res-col {
        flex: 1;

        .open-paana {
          display: block;
        }

        .open-digit {
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }

}