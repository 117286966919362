@import "../../../styles/colors";

.drawer {
  .logo-area {
    height: 70px;
    width: 240px;
    background-color: $drawer-background-color;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: white;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 5px;
      margin: 0;
    }
  }
}
