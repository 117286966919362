$primary-color:#070847;
$primary-color-dim: rgb(230, 106, 23);
//$primary-color:#1C3FAA;
//$primary-color-dim: rgb(46, 81, 187);
//$secondary-color: #f1f5f8;
$secondary-color: #f1f5f8;
$dim-black-color: #3b3848;

/**
 * DRAWER
 */
$drawer-background-color: $primary-color;
$drawer-inactive-item-fg-color: $secondary-color;

$drawer-active-item-fg-color: $dim-black-color;
$drawer-active-item-bg-color: $secondary-color;

$drawer-divider-bg-color: $primary-color-dim;

/**
 * Content Frame
 */
$content-frame-bg-color: $secondary-color;

/**
 * Floating Action Btn
 */
$floating-action-btn-bg-color: $primary-color;
$floating-action-btn-fg-color: $secondary-color;
$floating-action-btn-hover-bg-color: $primary-color-dim;

/**
 * APPBAR
 */
$appbar-bottom-divider-bg-color: rgb(253, 253, 253);

$appbar-menu-icon-color: $primary-color;
$appbar-menu-icon-hover-color: $primary-color-dim;