.captains-table {
  .documents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .license {
      height: 200px;
    }

    .cnic {
      height: 200px;
    }

    .number-plate {
      height: 200px;
    }
  }
}