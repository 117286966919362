.cities-fragment{
    position: relative;
    .city-add-btn{
        position: absolute;
        right: 10px;
        bottom: 10px;
        padding: 20px;
        background-color:black;

        &:hover{
            background-color: maroon;

        }
    }
}

.alert-container {
    position: absolute;
    width: 100%;

    .alert {
        position: absolute;
        width: 400px;
        right: 50px;

        .buttons_container {
            display: flex;
            justify-content: space-around;
            button {
            }
        }
    }
}
