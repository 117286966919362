@import "../../styles/colors";

.cool-chip {
  background: $primary-color;
  color: $secondary-color;
  padding: 4px 10px;
  border-radius: 20px;
  margin-right: 10px;

  &:hover {
    background: $primary-color-dim;
    cursor: pointer;
  }

  &.active {
    background: $dim-black-color;
  }
}