.mini-stat-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 150px;
  //background-color: #626ED4;
  padding: 16px;

  .upper-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .card-description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .mini-stat-img {
        width: 58px;
        height: 58px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 32px;
        }
      }

      .card-details {
        margin-left: 16px;

        .card-label {
          text-transform: uppercase;
        }

        .value-count {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .up, .down {
            margin-left: 8px;
          }

          .up {
            color: #02A499;
          }
          .down {
            color: #EC4561;
          }
        }
      }
    }
  }

  .lower-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
