@import "../../styles/colors";

.app-bar {
  display: flex;
  justify-content: space-between;
  line-height: 70px;
  height: 70px;

  .menu-icon {
    color: $appbar-menu-icon-color;

    &:hover {
      cursor: pointer;
      color: $appbar-menu-icon-hover-color;
    }
  }

  .l-h-s {
    display: flex;
    align-items: center;

    .menu-icon {
      margin-left: 16px;
    }
  }

  .r-h-s {
    display: flex;
    align-items: center;
  }
}
