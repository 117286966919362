@import "../../../styles/colors";

.splash-activity{
  background-color: #1C3FAA;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    opacity: 0.4;
    background-color: white;
    position: absolute;
    top: -50px;
    left: -90px;
    overflow: hidden;
  }
  &:after{
    content : '';
    width: 600px;
    height: 600px;
    border-radius: 50%;
    opacity: 0.4;
    background-color: white;
    position: absolute;
    bottom : -150px;
    right : -300px;
    overflow: hidden;

  }

  .heading{
    color: white;
    letter-spacing: 8px;
    font-size: 42px;
    margin-bottom: 20px;
  }

  .spinner{
    width: 40px;
    height: 40px;
    border-radius: 50%;

  }
}

